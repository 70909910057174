import { TimeDimensionGranularity } from '@cubejs-client/core';
import { useCubeQuery } from '@cubejs-client/react';
import { ProjectContext, ProjectContextType } from 'contexts/ProjectContext';
import { uniq } from 'lodash';
import { Layout, LayoutAxis, PlotData, PlotlyDataLayoutConfig } from 'plotly.js';
import { useCallback, useContext, useEffect, useState } from 'react';
import { paramOptions } from 'shared/Config';
import { HiResHydrographyCubeDatum } from '../CubeTypes';
import { getResultSetsDateRange } from '../getResultSetsDateRange';
import { plotDates } from '../Plot';
import { BaseChartProps } from '../types';
import { ChartSettings } from './Chart';

export type ParameterOptions = (
  | 'waterTempC'
  | 'oxygenSat'
  | 'oxygenConcentration'
  | 'salinityPsu'
  | 'visibility'
)[];

type ManualHydrographyCubeDatum = {
  'ManualHydrography.oxygenSaturationAvg': number;
  'ManualHydrography.oxygenConcentrationAvg': number;
  'ManualHydrography.salinityAvg': number;
  'ManualHydrography.waterTempAvg': number;
  'ManualHydrography.visibilityAvg': number;
  'ManualHydrographyLookup.depth': number;
  'ManualHydrography.measuredAt.minute': string;
};

type ForecastHydrographyCubeDatum = {
  'TessNewForecastHydrography.oxygenSaturationAvg': number;
  'TessNewForecastHydrography.oxygenConcentrationAvg': number;
  'TessNewForecastHydrography.salinityAvg': number;
  'TessNewForecastHydrography.waterTempAvg': number;
  'TessNewForecastHydrographyLookup.depth': number;
  'TessNewForecastHydrography.measuredAt.minute': string;
};

type HydrographyStructure = {
  contours: {
    [measure: string]: {
      x: string[];
      y: number[];
      z: number[];
      xRange: [string, string];
      zRange: [number, number];
    };
  };
  visibility: {
    x: string[];
    y: number[];
  };
};

const paramCubeMap = {
  waterTempC: 'waterTempAvg',
  oxygenSat: 'oxygenSaturationAvg',
  oxygenConcentration: 'oxygenConcentrationAvg',
  salinityPsu: 'salinityAvg',
  visibility: 'visibilityAvg'
};

const createVisibilityPlot = (
  visibilityData: { x: string[]; y: number[] },
  yaxis: number,
  range: number[]
): { plot: Partial<PlotData>; layout: Partial<LayoutAxis> } => {
  const plot: Partial<PlotData> = {
    mode: 'lines+markers',
    yaxis: 'y' + yaxis,
    x: visibilityData.x,
    y: visibilityData.y,
    hovertemplate:
      `<b>Visibility %{y:.2f} m</b></br>` + '<b>Time: %{x}</b><br>' + '<extra></extra>',
    name: 'meters (m)',
    showlegend: true,
    connectgaps: true,
    line: {
      color: 'rgb(255, 0, 0)'
    }
  };

  const yMin = Math.min(...(range ?? []));
  const yMax = Math.max(...(range ?? []));

  const layout: Partial<LayoutAxis> = {
    title: { text: 'Depth (m)', font: { size: 12 } },
    range: [yMax, yMin]
  };

  return { plot, layout };
};

const nonZero = (v: number) => Number(v) !== 0;

const transformSensorData = (
  sensorData: HiResHydrographyCubeDatum[],
  granularity: TimeDimensionGranularity,
  useOxygenConcentration: boolean
): HydrographyStructure => {
  const waterTempNonZero = sensorData.filter(
    (d) => Number(d['TessSensorHydrography.waterTempAvg']) !== 0
  );
  const waterTempX = waterTempNonZero.map(
    (d) => d[`TessSensorHydrography.measuredAt.${granularity}`]
  );
  const waterTempZ = waterTempNonZero.map((d) => Number(d['TessSensorHydrography.waterTempAvg']));

  const oxygenSatNonZero = sensorData.filter(
    (d) => Number(d['TessSensorHydrography.oxygenSaturationAvg']) !== 0
  );
  const oxygenSatX = oxygenSatNonZero.map(
    (d) => d[`TessSensorHydrography.measuredAt.${granularity}`]
  );
  const oxygenSatZ = oxygenSatNonZero.map((d) =>
    Number(d['TessSensorHydrography.oxygenSaturationAvg'])
  );

  const oxygenConcentrationNonZero = sensorData.filter(
    (d) => Number(d['TessSensorHydrography.oxygenConcentrationAvg']) !== 0
  );
  const oxygenConcentrationX = oxygenConcentrationNonZero.map(
    (d) => d[`TessSensorHydrography.measuredAt.${granularity}`]
  );
  const oxygenConcentrationZ = oxygenConcentrationNonZero.map((d) =>
    Number(d['TessSensorHydrography.oxygenConcentrationAvg'])
  );

  const salinityNonZero = sensorData.filter(
    (d) => Number(d['TessSensorHydrography.salinityAvg']) !== 0
  );
  const salinityX = salinityNonZero.map(
    (d) => d[`TessSensorHydrography.measuredAt.${granularity}`]
  );
  const salinityZ = salinityNonZero.map((d) => Number(d['TessSensorHydrography.salinityAvg']));

  const sensorContours = {
    waterTempC: {
      x: waterTempX,
      y: waterTempNonZero.map((d) => d['TessSensorHydrographyLookup.depth']),
      z: waterTempZ,
      xRange: [waterTempX[0], waterTempX[waterTempX.length - 1]],
      zRange: [Math.min(...waterTempZ.filter(nonZero)), Math.max(...waterTempZ)]
    },
    salinityPsu: {
      x: salinityX,
      y: salinityNonZero.map((d) => d['TessSensorHydrographyLookup.depth']),
      z: salinityZ,
      xRange: [salinityX[0], salinityX[salinityX.length - 1]],
      zRange: [Math.min(...salinityZ.filter(nonZero)), Math.max(...salinityZ)]
    }
  };

  if (useOxygenConcentration) {
    sensorContours['oxygenConcentration'] = {
      x: oxygenConcentrationX,
      y: oxygenConcentrationNonZero.map((d) => d['TessSensorHydrographyLookup.depth']),
      z: oxygenConcentrationZ,
      xRange: [oxygenConcentrationX[0], oxygenConcentrationX[oxygenConcentrationX.length - 1]],
      zRange: [Math.min(...oxygenConcentrationZ.filter(nonZero)), Math.max(...oxygenConcentrationZ)]
    };
  } else {
    sensorContours['oxygenSat'] = {
      x: oxygenSatX,
      y: oxygenSatNonZero.map((d) => d['TessSensorHydrographyLookup.depth']),
      z: oxygenSatZ,
      xRange: [oxygenSatX[0], oxygenSatX[oxygenSatX.length - 1]],
      zRange: [Math.min(...oxygenSatZ.filter(nonZero)), Math.max(...oxygenSatZ)]
    };
  }

  return {
    //@ts-ignore
    contours: sensorContours,
    visibility: {
      x: [],
      y: []
    }
  };
};

const transform = (
  data: (ManualHydrographyCubeDatum | ForecastHydrographyCubeDatum)[],
  cube: 'ManualHydrography' | 'TessNewForecastHydrography',
  granularity: TimeDimensionGranularity,
  parameters: ParameterOptions,
  minDepth?: number,
  maxDepth?: number
): HydrographyStructure => {
  // Ensure min and max depth from non-forecast data are represented in the forecast data set.
  // This avoids mis-matches between the nominal depth values included in the datasets and ensures the panes line up nicely.
  // We can slap null values in for the actual measures and let Plotly interpolate to those depths
  if (cube == 'TessNewForecastHydrography') {
    const uniqueTimes = uniq(
      Object.values(data).map((d) => d[`${cube}.measuredAt.${granularity}`])
    );
    const uniqueDepths = uniq(Object.values(data).map((d) => Math.abs(d[`${cube}Lookup.depth`])));
    const minDepthForecasts = Math.min.apply(null, uniqueDepths);
    const maxDepthForecasts = Math.max.apply(null, uniqueDepths);
    if (minDepth < minDepthForecasts) {
      // Need to add in the shallowe rdepth
      Object.values(uniqueTimes).map((d) =>
        data.push({
          'TessNewForecastHydrography.measuredAt.minute': d,
          'TessNewForecastHydrographyLookup.depth': minDepth,
          'TessNewForecastHydrography.oxygenSaturationAvg': null,
          'TessNewForecastHydrography.oxygenConcentrationAvg': null,
          'TessNewForecastHydrography.salinityAvg': null,
          'TessNewForecastHydrography.waterTempAvg': null
        })
      );
    }
    if (maxDepth < maxDepthForecasts) {
      // Need to add in the deep depth
      Object.values(uniqueTimes).map((d) =>
        data.push({
          'TessNewForecastHydrography.measuredAt.minute': d,
          'TessNewForecastHydrographyLookup.depth': maxDepth,
          'TessNewForecastHydrography.oxygenSaturationAvg': null,
          'TessNewForecastHydrography.oxygenConcentrationAvg': null,
          'TessNewForecastHydrography.salinityAvg': null,
          'TessNewForecastHydrography.waterTempAvg': null
        })
      );
    }
  }
  const contourData =
    // forecasts have negative depth values but for manual hydro negative depths are magic numbers. Hence the switch for the Math.abs
    cube == 'TessNewForecastHydrography'
      ? data.filter(
          (d) =>
            Math.abs(d[`${cube}Lookup.depth`]) >= 0 &&
            Math.abs(d[`${cube}Lookup.depth`]) <= maxDepth
        )
      : data.filter((d) => d[`${cube}Lookup.depth`] >= 0);
  const x = contourData.map<string>((d) => d[`${cube}.measuredAt.${granularity}`]);
  const y = contourData.map((d) => Math.abs(d[`${cube}Lookup.depth`]));
  const xRange: [string, string] = [x[0], x[x.length - 1]];

  const transformedData = {
    visibility: {
      x,
      y: data.map((d) => d[`${cube}.visibilityAvg`])
    },
    contours: {}
  };

  parameters
    .filter((param) => param !== 'visibility')
    .forEach((param) => {
      let subset = contourData.map((d) => d[`${cube}.${paramCubeMap[param]}`]);
      switch (param) {
        case 'oxygenSat':
          subset = subset.filter(nonZero).length > 0 ? subset : Array(x.length).fill(2);
          break;
        case 'oxygenConcentration':
          subset = subset.filter(nonZero).length > 0 ? subset : Array(x.length).fill(2);
          break;
        case 'salinityPsu':
          subset = subset.filter(nonZero).length > 0 ? subset : Array(x.length).fill(4);
          break;
        default:
          break;
      }
      transformedData.contours[param] = {
        x,
        y,
        z: subset,
        xRange,
        zRange: [Math.min(...subset.filter(nonZero)), Math.max(...subset)]
      };
    });
  return transformedData;
};

const createContourPlot = (
  data: HydrographyStructure,
  projectContext: ProjectContextType,
  options: {
    plotIdxOffset: number;
    xaxis: string;
    colorbarX?: number;
    zRanges: Record<string, Array<number>>;
  }
) => {
  const layouts: Partial<LayoutAxis>[] = [];
  const numOfPlots = Object.keys(data.contours).length + options.plotIdxOffset;

  const plotData: Partial<PlotData>[] = Object.entries(data.contours).map(
    ([measurement, measurementData], idx) => {
      const measurementConfig = paramOptions[measurement];
      const plot: Partial<PlotData> = {
        name: measurement,
        x: measurementData.x,
        y: measurementData.y,
        z: measurementData.z,
        type: 'contour' as const,
        line: {
          smoothing: 0.85
        },
        hovertemplate:
          `<b>${measurementConfig.name} %{z:.2f} ${measurementConfig.units} </b></br>` +
          `<b>Time: %{x} ${projectContext.timezone} </b><br>` +
          '<b>Depth: %{y} m</b><br>' +
          '<extra></extra>',

        zmin: options.zRanges[measurement][0],
        zmax: options.zRanges[measurement][1],

        //@ts-ignore
        contours: {
          coloring: 'fill',
          showlines: false
        },
        showscale: !!options.colorbarX,

        yaxis: `y${idx + 1}`,
        xaxis: options.xaxis,
        colorscale: measurementConfig.color,
        colorbar: {
          lenmode: 'fraction' as const,
          x: options.colorbarX,
          y: 1 - 1 / numOfPlots / 2 - idx * (1 / numOfPlots),
          len: ((1 / numOfPlots) * 3) / 4,
          ypad: 0,
          yanchor: 'middle' as const,
          title: measurementConfig.label,
          titleside: 'right' as const
        }
      };

      const maxDepth = Math.max(...measurementData.y);

      layouts.push({
        title: { text: 'Depth (m)', font: { size: 12 } },
        range: [1, maxDepth],
        autorange: 'reversed'
      });

      return plot;
    }
  );

  return {
    plotData,
    layouts
  };
};

const getZRanges = (factData: HydrographyStructure, forecastData: HydrographyStructure) => {
  const ranges = Object.entries(factData.contours).reduce(
    (prev, [measurement, measurementData]) => {
      prev[measurement] = [
        Math.min(measurementData?.zRange[0], forecastData.contours[measurement]?.zRange[0]),
        Math.max(measurementData?.zRange[1], forecastData.contours[measurement]?.zRange[1])
      ];

      return prev;
    },
    {
      waterTempC: [],
      oxygenSat: [],
      oxygenConcentration: [],
      salinityPsu: []
    }
  );

  if (factData?.visibility.y?.length >= 2) {
    ranges['visibility'] = [
      Math.min(...factData.visibility.y.filter((n) => n !== null)) - 1,
      Math.max(...factData.visibility.y) + 1
    ];
  }

  return ranges;
};

const useHydrographyPlot = ({
  granularity = 'minute',
  dateRange = 'from 7 days ago to now',
  chartRange,
  skip,
  refreshInterval,
  settings,
  onDataLoaded
}: BaseChartProps<ChartSettings>): {
  isLoading: boolean;
  error: Error;
  hasData: boolean;
  plot: PlotlyDataLayoutConfig;
} => {
  const projectContext = useContext(ProjectContext);

  const useForecasting = !settings?.skipForecasting && projectContext.forecasting;
  const graph = useCallback(
    (
      factData: HydrographyStructure,
      forecastData: HydrographyStructure,
      projectContext: ProjectContextType,
      options?: {
        useLocalMinMax: boolean;
        useForecasting: boolean;
      }
    ) => {
      let layouts: Partial<LayoutAxis>[] = [];
      const hasVisibility = factData.visibility.y.filter((y) => y).length > 0;
      const plotIdxOffset = hasVisibility ? 1 : 0;

      let zRanges: Record<string, Array<number>> = {};
      if (options?.useLocalMinMax) {
        zRanges = getZRanges(factData, forecastData);
      } else {
        zRanges = {
          waterTempC: paramOptions.waterTempC.range,
          oxygenSat: paramOptions.oxygenSat.range,
          oxygenConcentration: paramOptions.oxygenConcentration.range,
          salinityPsu: paramOptions.salinityPsu.range,
          visibility: paramOptions.visibility.range
        };
      }

      //Match forecasted to measured contours
      const measuredContours = Object.keys(factData.contours);
      const matchedContourKeys = Object.keys(forecastData.contours).filter((key) =>
        measuredContours.includes(key)
      );
      const matchedContours = matchedContourKeys.reduce((accContours, key) => {
        accContours[key] = forecastData.contours[key];
        return accContours;
      }, {});
      forecastData.contours = matchedContours;

      const measuredPlots = createContourPlot(factData, projectContext, {
        plotIdxOffset,
        xaxis: 'x1',
        colorbarX: 1,
        zRanges
      });
      const forecastedPlots = createContourPlot(forecastData, projectContext, {
        plotIdxOffset,
        xaxis: 'x2',
        zRanges
      });

      layouts = layouts.concat(measuredPlots.layouts);

      if (hasVisibility) {
        const visiblityPlot = createVisibilityPlot(
          factData.visibility,
          measuredPlots.plotData.length + 1,
          zRanges.visibility
        );
        measuredPlots.plotData.push(visiblityPlot.plot);
        layouts.push(visiblityPlot.layout);
      }

      layouts = layouts.concat(forecastedPlots.layouts);

      const xMin = factData.contours[Object.keys(factData.contours)?.[0]]?.xRange[0];
      const xMax = factData.contours[Object.keys(factData.contours)?.[0]]?.xRange[1];
      const xAxisCount = Math.max(measuredPlots.plotData.length, forecastedPlots.plotData.length);

      const hasForecastData =
        options?.useForecasting &&
        Object.values(forecastData.contours).every((d) => d.x.length !== 0);

      const title = settings?.site
        ? `Barge Hydrography - ${settings.site.name}`
        : `Barge Hydrography - All Sites`;

      const [minDate, maxDate] = plotDates(measuredPlots.plotData);

      const layout: Partial<Layout> = {
        title: settings?.showTitle && {
          text: title,
          y: 0.9
        },
        grid: {
          rows: xAxisCount,
          columns: hasForecastData ? 2 : 1
        },
        height: (Object.keys(factData.contours).length + (hasVisibility ? 1 : 0)) * 100 + 150,
        hovermode: 'closest',
        autosize: true,
        xaxis: {
          showspikes: true,
          spikemode: 'across',
          spikecolor: 'black',
          hoverformat: '%Y-%m-%d %H:00',
          domain: hasForecastData ? [0, 0.68] : [0, 1],
          range: chartRange ?? (xMin && xMax ? [xMin, xMax] : undefined),
          title: `${minDate} - ${maxDate} by ${granularity}`
        },
        xaxis2: {
          domain: [0.7, 1],
          title: 'Forecast',
          showspikes: true,
          spikemode: 'across',
          spikecolor: 'black'
        },
        legend: {
          orientation: 'v',
          x: 1,
          y: 0.03
        }
      };

      layouts.forEach((l, i) => {
        layout[`yaxis${i + 1}`] = l;
      });

      const data = hasForecastData
        ? [...measuredPlots.plotData, ...forecastedPlots.plotData]
        : measuredPlots.plotData;

      return {
        data,
        layout
      };
    },
    [chartRange, settings]
  );

  const {
    isLoading: manualLoading,
    error: manualError,
    resultSet: manualResult,
    refetch: manualRefetch
  } = useCubeQuery<ManualHydrographyCubeDatum>(
    {
      measures: settings.parameters.map((param) => `ManualHydrography.${paramCubeMap[param]}`),
      timeDimensions: [
        {
          dimension: 'ManualHydrography.measuredAt',
          granularity,
          dateRange
        }
      ],
      dimensions: ['ManualHydrographyLookup.depth'],
      filters: [
        {
          member: 'Site.id',
          operator: 'equals',
          values: [settings.site?.smbId.toString()]
        }
      ],
      timezone: projectContext.timezone
    },
    { skip: skip || !settings?.site }
  );

  const {
    isLoading: forecastLoading,
    error: forecastError,
    resultSet: forecastResult,
    refetch: forecastRefetch
  } = useCubeQuery<ForecastHydrographyCubeDatum>(
    {
      measures: settings.parameters
        .filter((param) => param !== 'visibility')
        .map((param) => `TessNewForecastHydrography.${paramCubeMap[param]}`),
      timeDimensions: [
        {
          dimension: 'TessNewForecastHydrography.measuredAt',
          granularity: 'hour',
          dateRange: 'from now to 2 days from now'
        }
      ],
      dimensions: ['TessNewForecastHydrographyLookup.depth'],
      filters: [
        {
          member: 'Site.id',
          operator: 'equals',
          values: [settings.site?.smbId.toString()]
        },
        { member: 'TessNewForecastHydrographyLookup.depth', operator: 'lte', values: ['0'] },
        {
          member: 'TessNewForecastHydrographyLookup.sublocation',
          operator: 'equals',
          values: ['house']
        }
      ],
      timezone: projectContext.timezone
    },
    { skip: (!useForecasting && skip) || !settings?.site }
  );

  const {
    isLoading: sensorLoading,
    error: sensorError,
    resultSet: sensorResult,
    refetch: sensorRefetch
  } = useCubeQuery<HiResHydrographyCubeDatum>({
    measures: [
      'TessSensorHydrography.waterTempAvg',
      'TessSensorHydrography.oxygenSaturationAvg',
      'TessSensorHydrography.oxygenConcentrationAvg',
      'TessSensorHydrography.salinityAvg'
    ],
    dimensions: ['TessSensorHydrographyLookup.sublocation', 'TessSensorHydrographyLookup.depth'],
    timeDimensions: [
      {
        dimension: 'TessSensorHydrography.measuredAt',
        granularity,
        dateRange
      }
    ],
    filters: [
      {
        member: 'Site.id',
        operator: 'equals',
        values: [settings.site?.smbId.toString()]
      },
      {
        member: 'TessSensorHydrographyLookup.sublocation',
        operator: 'contains',
        values: ['barge']
      },
      { member: 'TessSensorHydrographyLookup.depth', operator: 'gte', values: ['0'] }
    ],
    limit: 50000,
    order: {
      'TessSensorHydrography.measuredAt': 'asc'
    },
    timezone: projectContext.timezone
  });

  const [plot, setPlot] = useState(null);

  const start = chartRange?.[0].toISOString() ?? '';
  const end = chartRange?.[1].toISOString() ?? '';

  useEffect(() => {
    if (!settings.useSensor && (manualLoading || !manualResult)) return;
    if (projectContext.forecasting && (forecastLoading || !forecastResult)) return;
    if (settings.useSensor && (sensorLoading || !sensorResult)) return;

    let transformedFacts: HydrographyStructure;
    if (settings.useSensor) {
      transformedFacts = transformSensorData(
        sensorResult.rawData(),
        granularity,
        settings?.parameters?.includes('oxygenConcentration')
      );
    } else {
      transformedFacts = transform(manualResult.rawData(), 'ManualHydrography', granularity, [
        ...settings.parameters
      ]);
    }

    const forecast = forecastResult?.rawData() ?? [];

    if (onDataLoaded) {
      onDataLoaded(
        [manualResult, sensorResult],
        getResultSetsDateRange(sensorResult, settings.project.timezone)
      );
    }

    // Get depths from measured data to frame forecasts
    const minDepthFacts = Math.min.apply(null, transformedFacts.contours?.waterTempC?.y);
    const maxDepthFacts = Math.max.apply(null, transformedFacts.contours?.waterTempC?.y);

    const transformedForecasts = transform(
      forecast,
      'TessNewForecastHydrography',
      'hour',
      [...settings.parameters],
      minDepthFacts,
      maxDepthFacts
    );

    setPlot(
      graph(transformedFacts, transformedForecasts, projectContext, {
        useLocalMinMax: settings.useLocalMinMax,
        useForecasting: useForecasting
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    manualLoading,
    manualError,
    manualResult,
    forecastLoading,
    forecastError,
    forecastResult,
    sensorLoading,
    sensorResult,
    projectContext,
    useForecasting,
    granularity,
    settings.useSensor,
    settings.parameters.length,
    settings.useLocalMinMax,
    start,
    end
    // Causing endless render loop for some reason
    // onDataLoaded,
    // graph
  ]);

  useEffect(() => {
    if (refreshInterval) {
      const interval = setInterval(() => {
        manualRefetch();
        forecastRefetch();
        settings?.useSensor && sensorRefetch();
      }, refreshInterval);

      return () => clearInterval(interval);
    }
  }, [refreshInterval, settings?.useSensor, manualRefetch, forecastRefetch, sensorRefetch]);

  const hasData =
    settings.parameters.length > 0 &&
    (settings?.useSensor
      ? sensorResult?.rawData().length > 0
      : (manualResult?.rawData().length > 0 &&
          uniq(manualResult.rawData().map((d) => d['ManualHydrographyLookup.depth'])).length > 1) ||
        (forecastResult?.rawData().length > 0 &&
          uniq(forecastResult.rawData().map((d) => d['TessNewForecastHydrographyLookup.depth']))
            .length > 1));

  return {
    isLoading: manualLoading || forecastLoading || (settings.useSensor && sensorLoading),
    error: manualError || forecastError || (settings.useSensor && sensorError),
    hasData,
    plot
  };
};

export default useHydrographyPlot;
