import { gql, useQuery } from '@apollo/client';
import {
  Alert,
  AlertIcon,
  Center,
  Divider,
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack
} from '@chakra-ui/react';
import FormInput from 'components/Forms/FormInput';
import FormSelect from 'components/Forms/FormSelect';
import FormTextarea from 'components/Forms/FormTextarea';
import InfoPopover from 'components/InfoPopover';
import { ProjectContext } from 'contexts/ProjectContext';
import { AlarmFrequencyUnit, GetAlarmChartSetsQuery } from 'graphql/generated';
import { useContext, useEffect, useMemo } from 'react';
import { Control, useController, useWatch } from 'react-hook-form';
import AlarmNotificationList from '../AlarmNotificationList';
import { EditAlarmForm } from '../EditAlarmModal';

type NotificationSettingsProps = {
  control: Control<EditAlarmForm>;
};

const GET_ALARM_CHART_SETS = gql`
  query GetAlarmChartSets($projectId: Int!) {
    chartSets(projectId: $projectId, isProjectSet: false) {
      id
      name
      description
      charts {
        id
        displayName
      }
    }
  }
`;

const hoursIntervalOptions = [
  { label: '1', value: 1 },
  { label: '3', value: 3 },
  { label: '6', value: 6 },
  { label: '12', value: 12 },
  { label: '24', value: 24 }
];

// Insert clever function here...
const minutesIntervalOptions = [
  { label: '30', value: 30 },
  { label: '35', value: 35 },
  { label: '40', value: 40 },
  { label: '45', value: 45 },
  { label: '50', value: 50 },
  { label: '55', value: 55 }
];

const frequencyUnitOptions = [
  { label: 'Hours', value: AlarmFrequencyUnit.Hours },
  { label: 'Minutes', value: AlarmFrequencyUnit.Minutes }
];

const NotificationSettings = ({ control }: NotificationSettingsProps) => {
  const {
    field: { onChange }
  } = useController({ control, name: 'frequency' });

  const {
    field: { onChange: onChangeChartSet }
  } = useController({ control, name: 'chartSetId' });

  const [frequency, siteAlarms, chartSetId] = useWatch({
    control,
    name: ['frequency', 'siteAlarms', 'chartSetId']
  });

  const project = useContext(ProjectContext);

  const { data: chartSetData } = useQuery<GetAlarmChartSetsQuery>(GET_ALARM_CHART_SETS, {
    variables: { projectId: project?.id }
  });

  const chartSetOptions = useMemo(() => {
    if (!chartSetData) return [];
    return chartSetData.chartSets.map((cs) => ({ label: cs.name, value: cs }));
  }, [chartSetData]);

  const intervalOptions = useMemo(() => {
    return frequency.unit === AlarmFrequencyUnit.Minutes
      ? minutesIntervalOptions
      : hoursIntervalOptions;
  }, [frequency.unit]);

  useEffect(() => {
    const intervalOption = intervalOptions.find((io) => io.value === frequency.interval);
    if (!intervalOption) {
      onChange({ ...frequency, interval: intervalOptions[0].value });
    }
  }, [frequency, frequency.interval, frequency.unit, intervalOptions, onChange]);

  return (
    <>
      <Center m="20px">
        <Text fontSize="2xl">Settings</Text>
      </Center>

      <Text mb="10px">
        Create a name and description for your alarm and designate recipients for alert emails or
        text messages for each site. In-app notification settings can be edited in User Account
        settings.
      </Text>

      <VStack w="100%">
        <Alert status="info" variant="left-accent">
          <AlertIcon mb="5px" />
          &#123;site&#125; can be used as a site name placeholder in the name and description
          inputs. Ex. &apos;&#123;site&#125; - Alarm&apos; will be transformed to &apos;Gregory -
          Alarm&apos;
        </Alert>
        <FormInput
          data-cypress="alarm-name-input"
          label="Name of Alarm"
          control={control}
          name="name"
        />
        <FormTextarea
          data-cypress="alarm-description-input"
          label="Description"
          placeholder="Note: Description will be sent with the alarm notification"
          control={control}
          name="description"
        />

        <FormSelect
          data-cypress="alarm-chart-set"
          label="Chart Set"
          placeholder="Optional chart set..."
          tooltip="Include a chart set on the alarm notification email to add context to your alarm."
          control={control}
          name="chartSetId"
          options={chartSetOptions}
          value={chartSetOptions.find((cs) => cs.value.id === chartSetId)}
          onChange={(cs) => onChangeChartSet(cs?.value?.id)}
          isClearable={true}
        />

        <Divider />

        <Center m="20px">
          <Text fontSize="2xl">Notifications</Text>
          <InfoPopover ml="5px">
            Optional emails and texts you would like to send upon &apos;alert&apos; status of the
            alarm.
          </InfoPopover>
        </Center>
        <Tabs w="100%">
          <TabList overflowX="auto" pb="2px">
            {siteAlarms.map(({ site }) => {
              return <Tab key={site.id}>{site.name}</Tab>;
            })}
            {/* <Tab key="all">
            <ChevronLeftIcon color="blue.500" />
            All
            <ChevronRightIcon color="blue.500" />
          </Tab> */}
          </TabList>
          <TabPanels>
            {siteAlarms.map((_alarmSite, i) => {
              return (
                <TabPanel key={i}>
                  <AlarmNotificationList control={control} name={`siteAlarms.${i}.notify`} />
                </TabPanel>
              );
            })}
            {/* <TabPanel key="all">
            <AlarmNotificationList control={control} name={`notifyAll`} />
          </TabPanel> */}
          </TabPanels>
        </Tabs>
        <Divider />

        <HStack>
          <Text>Wait at least </Text>
          <>
            <FormSelect
              isDisabled={siteAlarms.flatMap((s) => s.notify).length === 0}
              w="100px"
              display="inline"
              control={control}
              name="frequency.interval"
              options={intervalOptions}
              onChange={(e) => onChange({ ...frequency, interval: e.value })}
              value={intervalOptions.find((hio) => hio.value === frequency.interval)}
            />
            <FormSelect
              isDisabled={siteAlarms.flatMap((s) => s.notify).length === 0}
              w="150px"
              display="inline"
              control={control}
              name="frequency.unit"
              options={frequencyUnitOptions}
              onChange={(e) => onChange({ ...frequency, unit: e.value })}
              value={frequencyUnitOptions.find((fuo) => fuo.value === frequency.unit)}
            />
          </>
          <Text>between notifications.</Text>
        </HStack>
      </VStack>
    </>
  );
};

export default NotificationSettings;
